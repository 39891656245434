import { render, staticRenderFns } from "./sku.vue?vue&type=template&id=0ba6ac36&scoped=true&"
import script from "./sku.vue?vue&type=script&lang=js&"
export * from "./sku.vue?vue&type=script&lang=js&"
import style0 from "./sku.vue?vue&type=style&index=0&id=0ba6ac36&prod&lang=css&"
import style1 from "./sku.vue?vue&type=style&index=1&id=0ba6ac36&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba6ac36",
  null
  
)

export default component.exports