<template>
  <div class="banner">
    <yd-slider autoplay="3000">
      <yd-slider-item v-for="(item, index) in banners" :key="index">
        <div class="img-box">
          <yqt-image
            class="img"
            :src="item.img_url | setHttps | ossProcess('style/w_960_h_auto')"
            fit="cover"
            alt="banner"
          />
        </div>
      </yd-slider-item>
    </yd-slider>
    <div class="home-icon" v-if="showStues && zgyh_show" @click.stop="goBack()">
      <img src="~@/assets/ic_back_28.png" alt />
    </div>
    <!-- <div class="home-icon" @click.stop="backHome()">
      <img src="~@/assets/icon_shangpin_home@2x.png" alt>
    </div>-->
    <!-- <div class="collect-icon" @click="collectGoods()">
      <img src="~@/assets/icon_shangpin_shouchang_light@2x.png" alt v-if="is_collect == 1">
      <img src="~@/assets/icon_shangpin_shouchang@2x.png" alt v-else>
    </div>-->
    <div class="collect-icon" v-if="showStues" @click="backHome()">
      <img src="~@/assets/icon_shangpin_home@2x.png" alt />
    </div>
  </div>
</template>
<script>
import { collectGoods } from "@/services/goodsApi.js";
import { isWeiXin, getStore } from "@/utils/common.js";
import { mapState } from "vuex";
import YqtImage from "@/components/image/Image";
export default {
  name: "banner",
  components: {
    YqtImage,
  },
  model: {
    prop: "is_collect",
    event: "change",
  },
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
    is_collect: {
      // 是否收藏
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      closeBid: [1436],
      showStues: true,
      zgyh_show: true, //中行控制显隐
    };
  },
  computed: {
    ...mapState({
      //商家id
      business_id: (state) => state.businessInfo.id || "",
      userInfo: (state) => state.userInfo, //用户信息
    }),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    backHome() {
      this.$router.replace({
        path: "/home",
      });
    },
    async collectGoods() {
      try {
        let data = await collectGoods(this.$route.query.item_id);
        if (data.code === 0) {
          let is_collect = this.is_collect == 1 ? 0 : 1;
          this.$emit("change", is_collect);
        }
      } catch (err) {
        throw err;
      }
    },
    closeRk() {
      if (!isWeiXin()) {
        if (this.closeBid.includes(this.business_id)) {
          this.showStues = false;
        }
      }
    },
    // 中行显隐控制
    isZh() {
      if (process.env.VUE_APP_ZHONGHANG) {
        let bids = JSON.parse(process.env.VUE_APP_ZHONGHANG);
        bids.forEach((val) => {
          if (val == JSON.parse(getStore("businessInfo")).id) {
            this.zgyh_show = false;
            return;
          }
        });
      }
    },
  },
  created() {
    this.closeRk();
    this.isZh();
  },
};
</script>
<style>
.banner .yd-slider-pagination .yd-slider-pagination-item {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #e1e1e1 !important;
}

.banner .yd-slider-pagination .yd-slider-pagination-item-active {
  width: 12px;
  height: 5px;
  border-radius: 5px;
  background-color: #333333 !important;
}
</style>
<style scoped lang="less">
.banner {
  width: 7.5rem;
  height: 7.5rem;
  position: relative;
  .img-box {
    width: 7.5rem;
    height: 7.5rem;
    .img {
      width: 7.5rem;
      height: 7.5rem;
    }
  }
  .home-icon,
  .collect-icon {
    width: 0.56rem;
    height: 0.56rem;
    border-radius: 0.56rem;
    position: absolute;
    z-index: 110;

    img {
      width: 0.56rem;
      height: 0.56rem;
    }
  }

  .home-icon {
    left: 0.3rem;
    top: 0.4rem;
  }

  .collect-icon {
    right: 0.3rem;
    top: 0.4rem;
  }
}
</style>
