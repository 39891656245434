<template>
  <div class="group-list">
    <div class="list-top" @click="moreGroup ? lookMore() : ''">
      <p class="tip">以下小伙伴正在发起团购，你可以直接拼团</p>
      <p class="look-more">
        <span v-if="moreGroup">更多拼团</span>
      </p>
    </div>
    <div class="list-content" v-if="groupList.length > 0">
      <div class="list-item" v-for="(item, index) in groupList" :key="index">
        <div class="item-info">
          <div class="user-img">
            <img v-if="item.heagurl" :src="item.heagurl" alt>
            <img v-else  src="../../assets/ic_head_default@3x.png" alt>
          </div>
          <div class="user-info">
            <p class="user-name">{{item.nickname}}</p>
            <yd-countdown :time="item.end_time" timetype="timestamp">
              <p class="count-down">还差 {{item.surplus_num}} 人，剩余{%h}:{%m}:{%s}</p>
            </yd-countdown>
          </div>
        </div>
        <div class="item-btn" @click="toDetail(item)">
          <img src="~@/assets/ic_pintuan@2x.png" alt>
        </div>
      </div>
    </div>
    <empty tip="拼团列表空空如也～" v-else></empty>
  </div>
</template>
<script>
import empty from "@/components/empty/Empty";

export default {
  name: "groupList",
  props: {
    moreGroup: {
      // 是否有更多拼团 文案 默认没有
      type: Boolean,
      default: false
    },
    groupList: {
      type: Array,
      default: () => []
    },
    group_buy_item_id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {};
  },
  components: {
    empty
  },
  methods: {
    lookMore() {
      this.$router.push({
        path: "/group-list",
        query: {
          group_buy_item_id: this.group_buy_item_id
        }
      });
    },
    toDetail(item){
      this.$router.push({
        path: "/group_buying",
        query: {
          group_buy_open_id: item.id
        }
      });
    },
  }
};
</script>
<style scoped lang="less">
@import "~@/common/less/variable.less";

.group-list {
  .list-top {
    width: 100%;
    height: 0.8rem;
    background: #f8f8f8;
    padding: 0 0.3rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tip {
      font-size: 0.24rem;
      color: #999999;
    }

    .look-more {
      font-size: 0.24rem;
      color: #666666;
    }
  }

  .list-content {
    padding: 0 0.3rem;
    box-sizing: border-box;
    background: #fff;

    .list-item {
      height: 1.4rem;
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-info {
        display: flex;
        align-items: center;

        .user-img {
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 0.8rem;
          margin-right: 0.2rem;

          img {
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 0.8rem;
          }
        }

        .user-info {
          height: 0.8rem;

          .user-name {
            font-size: 0.28rem;
            color: #333333;
            line-height: 0.4rem;
            margin-bottom: 3px;
          }

          .count-down {
            font-size: 0.24rem;
            color: #999999;
          }
        }
      }

      .item-btn {
        width: 1.28rem;
        height: 0.48rem;
        border-radius: 0.48rem;

        img {
          width: 1.28rem;
          height: 0.48rem;
        }
      }
    }

    .list-item:nth-last-child(1) {
      border-bottom: none;
    }
  }
}
</style>
