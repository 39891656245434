<template>
  <div class="flash-sale">
    <div :class="['goods-info', { redLeft: goodsType == 1 }, { greenLeft: goodsType == 2 }]">
      <div :class="['tip', { redLeft: goodsType == 1 }, { greenLeft: goodsType == 2 }]">
        <span>限时秒杀</span>
      </div>
      <div class="goods-price"  v-if="businessType == 2">
        <p class="real-price">
          <span></span>
          <!-- <span v-if="goodsType == 2">{{(goodsPrice.future_price * integralRate).toFixed(2)}}</span> -->
          <span v-if="goodsType == 2">{{priceShow(goodsPrice.display_type, goodsPrice.future_price, goodsPrice.max_integral_percentage)}}</span>
          <!-- <span v-else>{{(goodsPrice.real_price * integralRate).toFixed(2)}}</span> -->
          <span v-else>{{priceShow(goodsPrice.display_type, goodsPrice.real_price, goodsPrice.max_integral_percentage)}}</span>
          <!-- <span>积分</span> -->
        </p>
        <!-- <s v-if="goodsPrice.del_price">{{(goodsPrice.del_price * integralRate).toFixed(2)}}积分</s> -->
        <s v-if="goodsPrice.del_price">{{priceShow(goodsPrice.display_type, goodsPrice.del_price, goodsPrice.max_integral_percentage)}}</s>
      </div>
      <div class="goods-price"  v-else>
        <p class="real-price">
          <span>¥</span>
          <span v-if="goodsType == 2">{{goodsPrice.future_price}}</span>
          <span v-else>{{goodsPrice.real_price}}</span>
        </p>
        <s v-if="goodsPrice.del_price">¥{{goodsPrice.del_price}}</s>
      </div>
    </div>
    <div :class="['time-box', { redRight: goodsType == 1 }, { greenRight: goodsType == 2 }]">
      <span v-if="goodsType == 1" class="time-title redRight">距离结束还有</span>
      <span v-else-if="goodsType == 2" class="time-title greenRight">距离开始还有</span>

      <yd-countdown :time="time" timetype="timestamp" format="{%d}:{%h}:{%m}:{%s}">
        <div class="time">
          <div
              :class="['time-item', { redLeft: goodsType == 1 }, { greenLeft: goodsType == 2 }]"
          >{%d}
          </div>
          <span>:</span>
          <div
              :class="['time-item', { redLeft: goodsType == 1 }, { greenLeft: goodsType == 2 }]"
          >{%h}
          </div>
          <span>:</span>
          <div
              :class="['time-item', { redLeft: goodsType == 1 }, { greenLeft: goodsType == 2 }]"
          >{%m}
          </div>
          <span>:</span>
          <div
              :class="['time-item', { redLeft: goodsType == 1 }, { greenLeft: goodsType == 2 }]"
          >{%s}
          </div>
        </div>
      </yd-countdown>
    </div>
  </div>
</template>
<script>
import { priceShow } from "@/utils/goodsUtil.js";
export default {
  name: "flashSale",
  props: {
    goodsType: {
      // 商品类型 默认 限时购商品在售状态
      type: Number,
      default: 1
    },
    detail:{
      type: Object,
      default: {}
    },
    goodsPrice:{
      type: Object,
      default: {}
    },
    businessType:'',
    // integralRate:'',
  },
  data() {
    return {
      time:'',
    };
  },
  mounted() {
    // 预售状态
    console.log('123', this.goodsPrice)
    if(this.goodsType === 2){
      this.time = this.detail.limit_buy_info.start_time
    } else {
      this.time = this.detail.limit_buy_info.end_time
    }
    console.log(this.time);
  },
  methods: {
    // 积分3.0显示
		priceShow,
  }
};
</script>
<style scoped lang="less">
.flash-sale {
  width: 100%;
  height: 1rem;
  display: flex;

  .goods-info {
    width: 5.46rem;
    height: 1rem;
    display: flex;
    align-items: center;
    padding-left: 0.24rem;
    box-sizing: border-box;

    .tip {
      width: 1.44rem;
      height: 0.48rem;
      border-radius: 2px;
      background: #fff;
      font-size: 0.32rem;
      text-align: center;
      line-height: 0.48rem;
      margin-right: 0.3rem;
    }

    .goods-price {
      display: flex;
      color: #fff;
      align-items: baseline;

      .real-price {
        font-weight: bold;
        margin-right: 5px;

        span:nth-child(1) {
          font-size: 0.3rem;
        }

        span:nth-child(2) {
          font-size: 0.3rem;
        }
      }

      s {
        font-size: 0.16rem;
      }
    }
  }

  .redLeft {
    background: linear-gradient(
        227deg,
        rgba(255, 68, 68, 1) 0%,
        rgba(252, 62, 108, 1) 100%
    );
    color: #fd4060;
  }

  .greenLeft {
    background: rgba(0, 185, 76, 1);
    color: #00b94c;
  }

  .time-box {
    flex: 1;
    height: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .time-title {
      font-size: 0.22rem;
      line-height: 0.32rem;
    }

    .time {
      display: flex;
      align-items: center;
      justify-content: center;

      .time-item {
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 2px;
        text-align: center;
        font-size: 0.26rem;
        line-height: 0.4rem;
        color: #fff;
      }

      span {
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }

  .redRight {
    background: rgba(255, 236, 235, 1);
    color: #fd4060;
  }

  .greenRight {
    background: rgba(186, 255, 214, 1);
    color: #00b94c;
  }
}
</style>
