var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flash-sale" }, [
    _c(
      "div",
      {
        class: [
          "goods-info",
          { redLeft: _vm.goodsType == 1 },
          { greenLeft: _vm.goodsType == 2 },
        ],
      },
      [
        _c(
          "div",
          {
            class: [
              "tip",
              { redLeft: _vm.goodsType == 1 },
              { greenLeft: _vm.goodsType == 2 },
            ],
          },
          [_c("span", [_vm._v("限时秒杀")])]
        ),
        _vm.businessType == 2
          ? _c("div", { staticClass: "goods-price" }, [
              _c("p", { staticClass: "real-price" }, [
                _c("span"),
                _vm.goodsType == 2
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.priceShow(
                            _vm.goodsPrice.display_type,
                            _vm.goodsPrice.future_price,
                            _vm.goodsPrice.max_integral_percentage
                          )
                        )
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.priceShow(
                            _vm.goodsPrice.display_type,
                            _vm.goodsPrice.real_price,
                            _vm.goodsPrice.max_integral_percentage
                          )
                        )
                      ),
                    ]),
              ]),
              _vm.goodsPrice.del_price
                ? _c("s", [
                    _vm._v(
                      _vm._s(
                        _vm.priceShow(
                          _vm.goodsPrice.display_type,
                          _vm.goodsPrice.del_price,
                          _vm.goodsPrice.max_integral_percentage
                        )
                      )
                    ),
                  ])
                : _vm._e(),
            ])
          : _c("div", { staticClass: "goods-price" }, [
              _c("p", { staticClass: "real-price" }, [
                _c("span", [_vm._v("¥")]),
                _vm.goodsType == 2
                  ? _c("span", [_vm._v(_vm._s(_vm.goodsPrice.future_price))])
                  : _c("span", [_vm._v(_vm._s(_vm.goodsPrice.real_price))]),
              ]),
              _vm.goodsPrice.del_price
                ? _c("s", [_vm._v("¥" + _vm._s(_vm.goodsPrice.del_price))])
                : _vm._e(),
            ]),
      ]
    ),
    _c(
      "div",
      {
        class: [
          "time-box",
          { redRight: _vm.goodsType == 1 },
          { greenRight: _vm.goodsType == 2 },
        ],
      },
      [
        _vm.goodsType == 1
          ? _c("span", { staticClass: "time-title redRight" }, [
              _vm._v("距离结束还有"),
            ])
          : _vm.goodsType == 2
          ? _c("span", { staticClass: "time-title greenRight" }, [
              _vm._v("距离开始还有"),
            ])
          : _vm._e(),
        _c(
          "yd-countdown",
          {
            attrs: {
              time: _vm.time,
              timetype: "timestamp",
              format: "{%d}:{%h}:{%m}:{%s}",
            },
          },
          [
            _c("div", { staticClass: "time" }, [
              _c(
                "div",
                {
                  class: [
                    "time-item",
                    { redLeft: _vm.goodsType == 1 },
                    { greenLeft: _vm.goodsType == 2 },
                  ],
                },
                [_vm._v("{%d}\n        ")]
              ),
              _c("span", [_vm._v(":")]),
              _c(
                "div",
                {
                  class: [
                    "time-item",
                    { redLeft: _vm.goodsType == 1 },
                    { greenLeft: _vm.goodsType == 2 },
                  ],
                },
                [_vm._v("{%h}\n        ")]
              ),
              _c("span", [_vm._v(":")]),
              _c(
                "div",
                {
                  class: [
                    "time-item",
                    { redLeft: _vm.goodsType == 1 },
                    { greenLeft: _vm.goodsType == 2 },
                  ],
                },
                [_vm._v("{%m}\n        ")]
              ),
              _c("span", [_vm._v(":")]),
              _c(
                "div",
                {
                  class: [
                    "time-item",
                    { redLeft: _vm.goodsType == 1 },
                    { greenLeft: _vm.goodsType == 2 },
                  ],
                },
                [_vm._v("{%s}\n        ")]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }