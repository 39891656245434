<template>
  <div v-if="dialog" class="sku-dialog" @click="closeDialog()">
    <transition-group name="slide-top">
      <div v-if="contentShow" key="0" class="sku-main">
        <div class="sku-content" @click.stop>
          <div class="close" @click="closeDialog()">
            <img src="~@/assets/icon_guanbi@2x.png" alt />
          </div>

          <!-- 商品数据 -->
          <div class="goods-box">
            <div class="goods-img">
              <!-- <img :src="choosedGoods.img || goodsDetail.base_pic" alt> -->
              <img v-if="choosedGoods.img" :src="choosedGoods.img | setHttps" />
              <img v-else :src="goodsDetail.base_pic | setHttps" />
            </div>

            <!-- 购买类型为2展示情况 -->
            <div v-if="buyType == 2" class="goods-info">
              <p v-if="businessType == 2" class="price">
                <span v-if="price_status == 1" class="fs-weight">
                  {{
                    (
                      (choosedGoods.del_price || goodsDetail.del_price) *
                      integralRate
                    ).toFixed(2)
                  }}
                </span>
                <span v-if="price_status == 1" class="fs-weight">积分</span>
              </p>
              <p v-else class="price">
                <span v-if="price_status == 1" class="fs-weight">¥</span>
                <span v-if="price_status == 1" class="fs-weight">
                  {{ choosedGoods.del_price || goodsDetail.del_price }}
                </span>
              </p>
              <p v-if="goodsType == 1 || goodsType == 2" class="stock">
                库存：{{ goodsDetail.limit_item_info.stock
                }}{{ goodsDetail.unit || "件" }}
              </p>
              <p else class="stock">
                库存：{{ choosedGoods.stock }}{{ goodsDetail.unit || "件" }}
              </p>
              <p class="num">已选：{{ num }}{{ goodsDetail.unit || "件" }}</p>
            </div>
            <!-- 购买类型为2展示情况 -->

            <div v-else class="goods-info">
              <!-- 积分商城情况下展示 -->
              <p v-if="businessType == 2" class="price">
                <span v-if="price_status == 1" class="fs-weight">
                  {{
                    choosedGoods.price
                      ? goodsDetail.display_type === 2 ||
                        (goodsDetail.display_type === 3 &&
                          deducyion[0] === "1" &&
                          sureIntegral)
                        ? (choosedGoods.price - cashPart(sureIntegral)).toFixed(
                            2
                          ) +
                          "元+" +
                          sureIntegral +
                          "积分"
                        : priceShow(
                            goodsDetail.display_type,
                            choosedGoods.price,
                            goodsDetail.max_integral_percentage
                          )
                      : priceShow(
                          goodsDetail.display_type,
                          goodsDetail.price,
                          goodsDetail.max_integral_percentage
                        )
                  }}
                </span>

                <!-- 张家港定制展示 -->
                <span
                  v-if="
                    tripartite_type === 'VUE_APP_ZJG' &&
                    goodsDetail.display_type !== 1
                  "
                  class="zjg-max"
                >
                  积分最高抵扣{{ goodsDetail.max_integral_percentage }}%
                </span>
                <!-- 张家港定制展示 -->
              </p>
              <!-- 积分商城情况下展示 -->

              <p v-else class="price">
                <span v-if="price_status == 1" class="fs-weight">¥</span>
                <span v-if="price_status == 1" class="fs-weight">{{
                  choosedGoods.price || goodsDetail.price
                }}</span>
              </p>

              <div v-if="goodsType == 1 || goodsType == 2">
                <p
                  v-if="getVirtualStatus(goodsDetail.source_type)"
                  class="stock"
                >
                  库存：{{ goodsDetail.limit_item_info.stock
                  }}{{ goodsDetail.unit || "件" }}
                </p>
              </div>
              <div v-else>
                <p
                  v-if="getVirtualStatus(goodsDetail.source_type)"
                  class="stock"
                >
                  库存：{{ choosedGoods.stock }}{{ goodsDetail.unit || "件" }}
                </p>
              </div>

              <p class="num">已选：{{ num }}{{ goodsDetail.unit || "件" }}</p>
              <p
                class="num"
                v-if="
                  goodsDetail.item_limit * 1 !== -1 &&
                  customAccount('VUE_APP_ZHONGHANG')
                "
              >
                限购：{{ goodsDetail.item_limit }}{{ goodsDetail.unit || "件" }}
              </p>
            </div>
          </div>
          <!-- 商品数据 -->

          <!-- 多规格 -->
          <div v-if="!!skuListCopy.length" class="goods-sku">
            <div
              class="sku-item"
              v-for="(item, index) in skuListCopy"
              :key="index"
            >
              <div class="sku-property">{{ item.name }}</div>
              <div class="sku-value-box">
                <div
                  :class="[
                    'sku-value',
                    { active: property.active },
                    { noUse: !property.canUse },
                  ]"
                  v-for="(property, secIndex) in item.property_value_list"
                  :key="'0' + secIndex"
                  @click="
                    property.canUse ? chooseSkuItem(item.id, property.id) : ''
                  "
                >
                  {{ property.value }}
                </div>
              </div>
            </div>
          </div>
          <!-- 多规格 -->

          <!-- 分期商品进行抵扣选择 -->
          <div v-if="goodsDetail.display_type === 3" class="int-deducion">
            <yd-checkbox-group v-model="deducyion">
              <yd-checkbox val="1" shape="circle" :change="useIntegral()"
                >是否积分抵扣</yd-checkbox
              >
            </yd-checkbox-group>
          </div>
          <!-- 分期商品进行抵扣选择 -->

          <div v-if="integralShow()" class="integral-input">
            <div class="the-input">
              <div class="left">
                <span>使用积分数：</span>
              </div>
              <div class="right">
                <yd-input
                  v-model="integralNum"
                  type="number"
                  required
                  :show-success-icon="false"
                  :show-error-icon="false"
                  placeholder="请输入使用积分数"
                ></yd-input>
              </div>
              <div class="btn">
                <yd-button size="mini" @click.native="setIntegral"
                  >确定</yd-button
                >
              </div>
              <div
                v-if="kunShan || tripartite_type === 'VUE_APP_ZJG'"
                class="kunshan-tip"
              >
                您的积分余额为{{ balance }}
              </div>
            </div>

            <p v-if="choosedGoods.price" class="tip">
              积分使用区间：
              {{
                integralPart(
                  2,
                  choosedGoods.price,
                  goodsDetail.min_integral_percentage
                )
              }}
              ~{{
                integralPart(
                  2,
                  choosedGoods.price,
                  goodsDetail.max_integral_percentage
                )
              }}
              ，
              {{
                kunShan
                  ? "请确保输入积分是500的正整数倍!"
                  : tripartite_type === "VUE_APP_ZJG"
                  ? "请确保输入积分是5的正整数倍!"
                  : "请确保积分余额充足！"
              }}
            </p>
          </div>

          <!-- 选择购买数量 -->
          <div v-if="buyMore()" class="goods-num">
            <div class="num-title">
              购买数量（{{ goodsDetail.unit || "件" }}）
            </div>
            <div v-if="goodsType == 1 || goodsType == 2" class="num-spinner">
              <yd-spinner
                :callback="selectNum"
                :class="num == limitNum ? 'skuAddBtn' : ''"
                v-model="num"
                :max="
                  (limitNum < goodsDetail.limit_item_info.stock
                    ? limitNum
                    : goodsDetail.limit_item_info.stock) || 1
                "
              ></yd-spinner>
            </div>
            <div v-else class="num-spinner">
              <yd-spinner
                v-model="num"
                :max="choosedGoods.stock || 1"
              ></yd-spinner>
            </div>
          </div>
          <!-- 选择购买数量 -->
        </div>

        <div v-if="goodsInfo.stock_name == '无货'" class="sku-btn no-stock">
          该地区暂时无货
        </div>
        <div
          v-else-if="buyType == 0"
          class="sku-btn"
          :class="btnStatus"
          @click.stop="addCar()"
        >
          加入购物车
        </div>
        <div
          v-else-if="buyType == 1"
          class="sku-btn"
          :class="btnStatus"
          @click.stop="buyNow(1)"
        >
          立即{{
            tripartite("VUE_APP_TABEN") ||
            tripartite("VUE_APP_GUANGDALOGIN") ||
            customAccount("VUE_APP_CHAOWEI")
              ? "兑换"
              : "购买"
          }}
        </div>
        <div v-else-if="buyType == 2" class="sku-btn" @click.stop="buyNow(1)">
          单独购买
        </div>
        <div v-else-if="buyType == 3" class="sku-btn" @click.stop="buyNow(2)">
          我要开团
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { deepClone } from "@/utils/common.js";
import { checkGoods, addCar } from "@/services/goodsApi.js";
import { getSystemUserInfo } from "@/services/my";
import { baseUrl } from "../../utils/env";
import { mapState } from "vuex";
import {
  getGoodsType,
  priceShow,
  integralPart,
  cashPart,
} from "@/utils/goodsUtil.js";
import { getStore } from "@/utils/common";
import {
  tripartite,
  zjgKeepAlive,
  needBalance,
  customAccount,
} from "../../utils/custom";
import { orderMixin } from "@/mixins/orderMixin.js";

export default {
  name: "sku",
  mixins: [orderMixin],
  model: {
    prop: "dialog",
    event: "change",
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    goodsDetail: {
      type: Object,
      default: () => {},
    },
    buyType: {
      type: Number,
      default: 0,
    },
    goodsType: {
      // 商品类型
      type: Number,
      default: 0,
    },
    skuList: {
      type: Array,
      default: () => [],
    },
    skuInfo: {
      type: Array,
      default: () => [],
    },
    limitNum: {
      type: Number,
    },
  },
  data() {
    return {
      num: 1,
      contentShow: false,
      skuListCopy: [],
      skuInfoCopy: [],
      choosedID: [], // 选中的sku属性ID
      choosedGoods: {}, // 选中的商品
      integralNum: "", // 输入的使用积分数
      sureIntegral: "", // 确定的使用积分数
      btnStatus: "", // 按钮状态
      integralStatus: true, // 输入积分数是否在区间内
      kunShan: false, // 是否昆山农商行
      balance: null, // 用户积分余额
      deducyion: [],
    };
  },
  computed: {
    ...mapState({
      businessType: (state) => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      price_status: (state) => state.config.price_status, // 是否展示价格 1展示 0 不展示
      mall_price_status: (state) => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
      integralRate: (state) => state.config.integral_rate, // 积分比例
      tripartite_type: (state) => state.tripartite_type, // 三方商城类型
    }),
    goodsInfo() {
      return this.$store.state.goodsInfo;
    },
  },
  methods: {
    // 积分3.0显示
    priceShow,

    // 现金转积分
    integralPart,

    // 积分转现金
    cashPart,

    // 三方商城判断
    tripartite,
    customAccount,
    selectNum(val) {},

    chooseNum(num) {
      this.num = parseInt(this.num) + num;
    },

    closeDialog() {
      this.contentShow = false;
      // this.initSku();
      setTimeout(() => {
        this.$emit("change", false);
      }, 200);
    },
    // 报错弹窗
    errDialog(data) {
      this.$dialog.toast({
        mes: data.msg,
        timeout: 1500,
        icon: "none",
      });
    },

    // 积分抵扣选择情况,条件满足为选中状态，不满足为未选中状态
    useIntegral() {
      if (this.deducyion.length > 0) {
        if (this.sureIntegral) {
          this.btnStatus = "";
          this.integralStatus = true;
        } else {
          this.btnStatus = "no-stock";
          this.integralStatus = false;
        }
      } else {
        this.btnStatus = "";
        this.integralStatus = true;
      }
    },

    // 积分输入框是否显示
    integralShow() {
      const status1 =
          this.choosedGoods.price && this.goodsDetail.display_type === 2, // 积分+现金商品
        status2 = this.choosedGoods.price && this.deducyion[0] === "1", // 昆山分期商品选择积分抵用
        status3 =
          this.tripartite_type === "VUE_APP_ZJG" &&
          this.goodsDetail.display_type !== 1; // 张家港商城
      if (status1 || status2 || status3) {
        return true;
      } else {
        return false;
      }
    },

    // 购买数量状态
    buyMore() {
      const status1 = !this.getVirtualStatus(this.goodsDetail.source_type), // 虚拟产品
        status2 =
          this.goodsDetail.display_type === 2 &&
          this.bid !== Number(process.env.VUE_APP_KUNSHAN), // 积分+现金类型商品且不是昆山商城
        status3 = this.goodsDetail.display_type === 3, // 昆山分期商品
        status4 =
          this.tripartite_type === "VUE_APP_ZJG" &&
          this.goodsDetail.display_type !== 1; // 张家港商城
      if (status1 || status2 || status3 || status4) {
        return false;
      } else {
        return true;
      }
    },

    // 添加购物车
    async addCar() {
      if (!this.choosedGoods.item_id) {
        this.$dialog.toast({
          mes: "请选择商品属性",
          timeout: 1500,
          icon: "none",
        });
        return;
      }

      // 不符合积分区间
      if (!this.integralStatus) {
        return;
      }
      let limit_buy_item_id = "";
      if (this.goodsDetail.limit_item_info) {
        this.goodsType = getGoodsType(this.goodsDetail);
        if (this.goodsType == 1) {
          limit_buy_item_id = this.goodsDetail.limit_item_info.id;
        }
      }
      try {
        let data = await addCar(
          this.choosedGoods.item_id,
          this.choosedGoods.id,
          this.num,
          limit_buy_item_id,
          this.sureIntegral ? this.sureIntegral : 0
        );

        if (data.code === 0) {
          this.$dialog.toast({
            mes: "加入购物车成功",
            timeout: 1500,
            icon: "none",
            callback: () => {
              this.closeDialog();
            },
          });
        } else {
          this.errDialog(data);
        }
      } catch (err) {
        throw err;
      }
    },

    // 立即购买
    buyNow(is_group) {
      // is_group   1: 不是拼团  2：是拼团
      if (!this.choosedGoods.item_id) {
        this.$dialog.toast({
          mes: "请选择商品属性",
          timeout: 1500,
          icon: "none",
        });
        return;
      }
      if (
        this.num > this.goodsDetail.item_limit &&
        this.goodsDetail.item_limit * 1 !== -1
      ) {
        this.$dialog.toast({
          mes: `当前商品最多购买${this.goodsDetail.item_limit}${
            this.goodsDetail.unit || "件"
          },您已达到购买限制！`,
          timeout: 1500,
          icon: "none",
        });
        return;
      }
      // 不符合积分区间
      if (!this.integralStatus) {
        return;
      }

      let data = {
        item_id: this.choosedGoods.item_id,
        is_group: is_group,
        number: this.num,
        goodsType: this.goodsType,
        limit_buy_item_id: this.goodsDetail.limit_item_info
          ? this.goodsDetail.limit_item_info.id
          : "",
        integral_usage: this.sureIntegral,
      };
      if (is_group == 2) {
        data.group_buy_item_id = this.goodsDetail.group_buy_item_info.id;
      }
      if (this.choosedGoods.id) {
        data.item_property_id = this.choosedGoods.id;
      }
      this.$store.commit("setSettleData", data);
      this.$store.commit("clearCreateOrder");
      // window.location.href = `${baseUrl}/#/submit_order?add_type=1`;
      this.$router.push({
        path: "/submit_order",
        query: {
          add_type: 1,
          item_active_type: this.$route.query.item_active_type,
          activity_id: this.$route.query.activity_id,
        },
      });
    },
    // 选择sku属性时
    chooseSkuItem(sku_id, value_id) {
      // sku_id: sku属性id,  value_id: 对应属性下的值id
      let property_list = this.skuListCopy.find(
        (item) => item.id === sku_id
      ).property_value_list;

      for (let i = 0; i < property_list.length; i++) {
        if (property_list[i].id === value_id) {
          property_list[i].active = !property_list[i].active;
          let choosedObj = this.choosedID.find(
            (item) => item.sku_id === sku_id
          );
          if (property_list[i].active) {
            if (choosedObj) {
              // console.log({choosedObj});
              choosedObj.value_id = value_id;
            } else {
              this.choosedID.push({ sku_id, value_id });
            }
          } else {
            let index;
            for (let i = 0; i < this.choosedID.length; i++) {
              if (this.choosedID[i].sku_id === sku_id) {
                index = i;
              }
            }
            this.choosedID.splice(index, 1);
            this.choosedGoods = {};
          }
        } else {
          property_list[i].active = false;
        }
      }
      this.filterUsableSku();
      if (this.kunShan) {
        this.integralNum = "";
        if (this.goodsDetail.display_type === 2) {
          this.btnStatus = "no-stock";
          this.integralStatus = false;
        }
      } else {
        // 积分输入框赋值，张家港商城默认0
        if (
          this.tripartite_type === "VUE_APP_ZJG" &&
          this.goodsDetail.display_type !== 1
        ) {
          this.integralNum = 0;
          this.sureIntegral = 0;
          this.integralStatus = false;
          this.btnStatus = "no-stock";
        } else {
          this.integralNum = integralPart(
            this.choosedGoods.display_type,
            this.choosedGoods.price,
            this.goodsDetail.max_integral_percentage
          );
          this.sureIntegral = this.integralNum;
        }
      }

      // 分期商品默认没有选中的积分数
      if (
        this.goodsDetail.display_type !== 3 &&
        this.tripartite_type !== "VUE_APP_ZJG"
      ) {
        this.sureIntegral = integralPart(
          this.choosedGoods.display_type,
          this.choosedGoods.price,
          this.goodsDetail.max_integral_percentage
        );
      }
    },

    // 根据 choosedID 获取所有可选的sku属性
    getUsableProperty() {
      let obj = {};
      let choosedID = this.choosedID;
      let lastChoosedID = null;
      if (choosedID.length > 0) {
        lastChoosedID = choosedID[choosedID.length - 1];
        let key = lastChoosedID.sku_id;
        obj[key] = this.usableProperty[key];
      }
      let useList = this.getUseList();
      for (let i = 0; i < useList.length; i++) {
        for (let j = 0; j < useList[i].info.length; j++) {
          let key = useList[i].info[j].pro_info[0].property_id;
          let value = useList[i].info[j].pro_info[0].id;
          if (!lastChoosedID || key !== lastChoosedID.sku_id) {
            if (obj[key]) {
              if (obj[key].indexOf(value) === -1) {
                obj[key].push(value);
              }
            } else {
              obj[key] = new Array();
              obj[key].push(value);
            }
          }
        }
      }
      this.usableProperty = obj;
      return this.usableProperty;
    },

    // 判断是否是可选商品
    judgeInfo(info, choosedID) {
      if (info.length === 0) return false;
      for (let i = 0; i < choosedID.length; i++) {
        let property = info.find(
          (item) => item.pro_info[0].property_id === choosedID[i].sku_id
        );
        if (property.pro_info[0].id !== choosedID[i].value_id) {
          return false;
        }
      }
      return true;
    },

    // 根据 choosedID 获取 可选择商品列表；
    getUseList() {
      let list = [];
      let skuInfoList = this.skuInfoCopy;
      let choosedID = this.choosedID;
      if (choosedID.length === 0) {
        list = skuInfoList;
      } else {
        for (let i = 0; i < skuInfoList.length; i++) {
          if (this.judgeInfo(skuInfoList[i].info, choosedID)) {
            list.push(skuInfoList[i]);
          }
        }
      }
      if (choosedID.length === this.skuListCopy.length) {
        this.choosedGoods = list[0];
        this.checkGoods();
      }
      return list;
    },

    // 筛选可以使用的sku属性
    filterUsableSku() {
      // 获取可选的sku属性;
      let usableProperty = this.getUsableProperty();
      let skuList = this.skuListCopy;
      for (let key in usableProperty) {
        let property_value_list = skuList.find(
          (item) => item.id == key
        ).property_value_list;
        for (let i = 0; i < property_value_list.length; i++) {
          if (usableProperty[key].indexOf(property_value_list[i].id) > -1) {
            property_value_list[i].canUse = true;
          } else {
            property_value_list[i].canUse = false;
          }
        }
      }
    },

    // 判断商品有货无货，判断运费
    async checkGoods() {
      try {
        let activedAddress = this.$store.state.activedAddress;
        let data = {
          item_id: this.choosedGoods.item_id,
          province_id: activedAddress.province_id,
          city_id: activedAddress.city_id,
          area_id: activedAddress.area_id,
          town_id: activedAddress.street_id,
          item_property_id: this.choosedGoods.id,
        };
        let checkData = await checkGoods(data);
        if (checkData.code === 0) {
          this.$store.commit("setGoodsInfo", {
            postage: checkData.data.postage,
            stock_name: checkData.data.stock_name,
          });
        } else {
          this.$dialog.toast({
            mes: checkData.msg,
            timeout: 1500,
            icon: "none",
            callback: () => {
              this.closeDialog();
            },
          });
        }
      } catch (err) {
        throw err;
      }
    },

    // 初始化sku
    initSku() {
      // skuList， skuInfo进行深拷贝
      this.skuListCopy = deepClone(this.skuList);
      this.skuInfoCopy = deepClone(this.skuInfo);
      this.choosedGoods = this.skuInfoCopy[0];
      let skuListCopy = this.skuListCopy;
      this.choosedID = [];
      this.isKs();
      this.customShop();
      if (skuListCopy.length === 0) {
        if (this.kunShan) {
          if (this.goodsDetail.display_type === 2) {
            this.btnStatus = "no-stock";
            this.integralStatus = false;
          }
        } else {
          // 积分输入框赋值，张家港商城默认0
          if (
            this.tripartite_type === "VUE_APP_ZJG" &&
            this.goodsDetail.display_type !== 1
          ) {
            this.integralNum = 0;
            this.sureIntegral = 0;
            this.integralStatus = false;
            this.btnStatus = "no-stock";
          } else {
            this.integralNum = integralPart(
              this.goodsDetail.display_type,
              this.goodsDetail.price,
              this.goodsDetail.max_integral_percentage
            );
            this.sureIntegral = this.integralNum;
            this.integralStatus = true;
            this.btnStatus = "";
          }
        }
        // 分期商品默认没有选中的积分数
        if (
          this.goodsDetail.display_type !== 3 &&
          this.tripartite_type !== "VUE_APP_ZJG"
        ) {
          this.sureIntegral = integralPart(
            this.goodsDetail.display_type,
            this.goodsDetail.price,
            this.goodsDetail.max_integral_percentage
          );
        }
        return;
      } else {
        this.integralStatus = true;
        this.btnStatus = "";
      }
      this.choosedGoods = {};
      // 加入sku属性的是否选中(active) 和 是否可用(canUse)
      for (let i = 0; i < skuListCopy.length; i++) {
        for (let j = 0; j < skuListCopy[i].property_value_list.length; j++) {
          let obj = skuListCopy[i].property_value_list[j];
          this.$set(obj, "active", false);
          this.$set(obj, "canUse", false);
        }
      }
      this.filterUsableSku();
    },

    // 确定手动输入的积分
    setIntegral() {
      zjgKeepAlive();

      // console.log(this.choosedGoods);
      const min = integralPart(
          2,
          this.choosedGoods.price,
          this.goodsDetail.min_integral_percentage
        ),
        max = integralPart(
          2,
          this.choosedGoods.price,
          this.goodsDetail.max_integral_percentage
        );
      if (this.integralNum >= Number(min) && this.integralNum <= Number(max)) {
        this.meetKs(this.integralNum);
      } else {
        this.integralStatus = false;
        this.btnStatus = "no-stock";
        this.$dialog.toast({
          mes: "您输入的积分不在可使用积分范围内，请重新输入！",
          timeout: 2000,
        });
      }
    },

    // 获取用户积分余额
    async getBalance() {
      try {
        const res = await getSystemUserInfo();
        if (res.code === 0) {
          const data = res.data;
          this.balance = data.integral;
        }
      } catch (e) {
        //TODO handle the exception
      }
    },

    // 是否为昆山农商行
    isKs() {
      if (tripartite("VUE_APP_KUNSHAN")) {
        this.kunShan = true;
        this.getBalance();
      }
    },

    // 定制商城所需处理
    customShop() {
      // 是否需要展示余额判断
      const balanceStatus = needBalance.indexOf(this.tripartite_type);
      if (balanceStatus === 0) {
        this.getBalance();
      }
    },

    // 当为昆山农商行时判断输入积分是否为500的正整数倍
    meetKs(int) {
      if (this.kunShan) {
        this.conformIntegral(int, 500);
      } else {
        if (this.tripartite_type === "VUE_APP_ZJG") {
          this.conformIntegral(int, 5);
        } else {
          this.sureIntegral = this.integralNum;
          this.btnStatus = "";
          this.integralStatus = true;
        }
      }
    },

    // 判断积分是否是某数的正整数倍
    conformIntegral(int, num) {
      if (int % num === 0) {
        this.sureIntegral = this.integralNum;
        this.integralStatus = true;
        this.btnStatus = "";
      } else {
        this.integralStatus = false;
        this.btnStatus = "no-stock";
        this.$dialog.toast({
          mes: `您输入的积分不是${num}的正整数倍，请重新输入！`,
          timeout: 2000,
        });
      }
    },
  },
  created() {
    // this.initSku();
    // console.log(this.goodsDetail);
  },
  watch: {
    num(e) {
      if (e < 1) {
        this.num = 1;
      }
      if (e > 99) {
        this.num = 99;
      }
    },
    dialog(e) {
      setTimeout(() => {
        this.contentShow = e;
      }, 20);
    },
  },
};
</script>
<style>
.yd-spinner-square span {
  border-radius: 24px;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.yd-spinner {
  width: 2.6rem !important;
}
.yd-spinner > input {
  margin: 0 0.3rem;
  width: 40% !important;
}

.yd-spinner-square span i:after {
  color: var(--main-color);
}

.skuAddBtn > span:nth-child(3) {
  border: 1px solid #ccc;
}
.skuAddBtn > span:nth-child(3) i:after {
  color: #ccc;
}
</style>
<style scoped lang="less">
@import "~@/common/less/variable.less";
@import "~@/common/less/base.less";

.sku-dialog {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding-bottom: 0.98rem;
  box-sizing: border-box;

  .sku-main {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding-bottom: 0.98rem;
    box-sizing: border-box;
  }

  .sku-content {
    width: 100%;
    background: #fff;
    padding: 0.3rem;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    z-index: 999;

    .close {
      position: absolute;
      width: 0.32rem;
      height: 0.32rem;
      border-radius: 0.32rem;
      right: 0.4rem;
      top: 0.4rem;

      img {
        width: 0.32rem;
        height: 0.32rem;
        border-radius: 0.32rem;
      }
    }

    .goods-box {
      padding-bottom: 0.3rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e1e1e1;

      .goods-img {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 8px;

        img {
          width: 1.8rem;
          height: 1.8rem;
        }
      }

      .goods-info {
        padding-top: 0.3rem;
        box-sizing: border-box;
        color: #333;

        .price {
          margin-bottom: 0.24rem;

          .zjg-max {
            color: var(--main-color);
            font-size: 0.2rem !important;
          }

          span:nth-child(1) {
            font-size: 0.36rem;
          }

          span:nth-child(2) {
            font-size: 0.5rem;
          }
        }

        .stock,
        .num {
          font-size: 0.24rem;
          line-height: 0.34rem;
        }

        .fs-weight {
          font-weight: 500;
        }
      }
    }

    .goods-sku {
      // padding: 0.3rem 0 1rem;
      padding-top: 0.3rem;
      box-sizing: border-box;
      max-height: 6.68rem;
      overflow-y: auto;

      .sku-item {
        margin-bottom: 0.2rem;

        .sku-property {
          font-size: 0.28rem;
          color: #333333;
          line-height: 0.4rem;
          margin-bottom: 0.2rem;
        }

        .sku-value-box {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .sku-value {
            padding: 0.1rem 0.16rem;
            box-sizing: border-box;
            background: #f5f5f5;
            border-radius: 4px;
            font-size: 0.22rem;
            color: #333;
            margin-right: 0.3rem;
            margin-bottom: 0.1rem;
            border: 1px solid transparent;
          }

          .active {
            border: 1px solid var(--main-color);
            color: var(--main-color);
          }

          .noUse {
            color: #999999;
          }
        }
      }
    }

    .int-deducion {
      margin: 0.1rem;
    }

    .integral-input {
      padding: 0.2rem 0;

      .the-input {
        display: flex;
        margin-bottom: 0.2rem;

        .left {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          color: #555;
          font-size: 0.28rem;
          white-space: nowrap;
        }

        .right {
          width: 60%;
          min-height: 0.5rem;
          border: 1px solid #eef1f5;
          color: #525252;
          text-align: right;
          font-size: 0.26rem;
          // padding: 0 .24rem;
          padding-left: 0.1rem;
          -webkit-box-pack: end;
          justify-content: flex-end;
        }

        .btn {
          display: flex;
          align-items: center;
          margin-left: 0.2rem;

          .yd-btn-primary {
            background: var(--main-color);
          }
        }

        .kunshan-tip {
          color: red;
          font-size: 0.25rem;
          margin-left: 0.1rem;
        }
      }

      .tip {
        color: red;
        font-size: 0.25rem;
        line-height: 0.2rem;
      }
    }

    .goods-num {
      padding: 0.3rem 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #e1e1e1;

      .num-title {
        font-size: 0.28rem;
        color: #333333;
      }

      .num-spinner {
        display: flex;
        align-items: center;

        img {
          width: 0.52rem;
          height: 0.52rem;
          border-radius: 0.52rem;
        }

        input {
          border: none;
          font-size: 0.36rem;
          color: #333333;
          text-align: center;
          width: 0.8rem;
        }
      }
    }
  }

  .sku-btn {
    width: 100%;
    height: 0.98rem;
    position: fixed;
    bottom: 0;
    text-align: center;
    line-height: 0.98rem;
    font-size: 0.32rem;
    color: #fff;
    background: var(--main-color);
    z-index: 991;
  }

  .no-stock {
    background: #999999;
  }
}
</style>
