var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c(
        "div",
        {
          staticClass: "sku-dialog",
          on: {
            click: function ($event) {
              return _vm.closeDialog()
            },
          },
        },
        [
          _c("transition-group", { attrs: { name: "slide-top" } }, [
            _vm.contentShow
              ? _c("div", { key: "0", staticClass: "sku-main" }, [
                  _c(
                    "div",
                    {
                      staticClass: "sku-content",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "close",
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/icon_guanbi@2x.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "goods-box" }, [
                        _c("div", { staticClass: "goods-img" }, [
                          _vm.choosedGoods.img
                            ? _c("img", {
                                attrs: {
                                  src: _vm._f("setHttps")(_vm.choosedGoods.img),
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: _vm._f("setHttps")(
                                    _vm.goodsDetail.base_pic
                                  ),
                                },
                              }),
                        ]),
                        _vm.buyType == 2
                          ? _c("div", { staticClass: "goods-info" }, [
                              _vm.businessType == 2
                                ? _c("p", { staticClass: "price" }, [
                                    _vm.price_status == 1
                                      ? _c(
                                          "span",
                                          { staticClass: "fs-weight" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  (
                                                    (_vm.choosedGoods
                                                      .del_price ||
                                                      _vm.goodsDetail
                                                        .del_price) *
                                                    _vm.integralRate
                                                  ).toFixed(2)
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.price_status == 1
                                      ? _c(
                                          "span",
                                          { staticClass: "fs-weight" },
                                          [_vm._v("积分")]
                                        )
                                      : _vm._e(),
                                  ])
                                : _c("p", { staticClass: "price" }, [
                                    _vm.price_status == 1
                                      ? _c(
                                          "span",
                                          { staticClass: "fs-weight" },
                                          [_vm._v("¥")]
                                        )
                                      : _vm._e(),
                                    _vm.price_status == 1
                                      ? _c(
                                          "span",
                                          { staticClass: "fs-weight" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.choosedGoods.del_price ||
                                                    _vm.goodsDetail.del_price
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                              _vm.goodsType == 1 || _vm.goodsType == 2
                                ? _c("p", { staticClass: "stock" }, [
                                    _vm._v(
                                      "\n              库存：" +
                                        _vm._s(
                                          _vm.goodsDetail.limit_item_info.stock
                                        ) +
                                        _vm._s(_vm.goodsDetail.unit || "件") +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "p",
                                { staticClass: "stock", attrs: { else: "" } },
                                [
                                  _vm._v(
                                    "\n              库存：" +
                                      _vm._s(_vm.choosedGoods.stock) +
                                      _vm._s(_vm.goodsDetail.unit || "件") +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c("p", { staticClass: "num" }, [
                                _vm._v(
                                  "已选：" +
                                    _vm._s(_vm.num) +
                                    _vm._s(_vm.goodsDetail.unit || "件")
                                ),
                              ]),
                            ])
                          : _c("div", { staticClass: "goods-info" }, [
                              _vm.businessType == 2
                                ? _c("p", { staticClass: "price" }, [
                                    _vm.price_status == 1
                                      ? _c(
                                          "span",
                                          { staticClass: "fs-weight" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.choosedGoods.price
                                                    ? _vm.goodsDetail
                                                        .display_type === 2 ||
                                                      (_vm.goodsDetail
                                                        .display_type === 3 &&
                                                        _vm.deducyion[0] ===
                                                          "1" &&
                                                        _vm.sureIntegral)
                                                      ? (
                                                          _vm.choosedGoods
                                                            .price -
                                                          _vm.cashPart(
                                                            _vm.sureIntegral
                                                          )
                                                        ).toFixed(2) +
                                                        "元+" +
                                                        _vm.sureIntegral +
                                                        "积分"
                                                      : _vm.priceShow(
                                                          _vm.goodsDetail
                                                            .display_type,
                                                          _vm.choosedGoods
                                                            .price,
                                                          _vm.goodsDetail
                                                            .max_integral_percentage
                                                        )
                                                    : _vm.priceShow(
                                                        _vm.goodsDetail
                                                          .display_type,
                                                        _vm.goodsDetail.price,
                                                        _vm.goodsDetail
                                                          .max_integral_percentage
                                                      )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.tripartite_type === "VUE_APP_ZJG" &&
                                    _vm.goodsDetail.display_type !== 1
                                      ? _c("span", { staticClass: "zjg-max" }, [
                                          _vm._v(
                                            "\n                积分最高抵扣" +
                                              _vm._s(
                                                _vm.goodsDetail
                                                  .max_integral_percentage
                                              ) +
                                              "%\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _c("p", { staticClass: "price" }, [
                                    _vm.price_status == 1
                                      ? _c(
                                          "span",
                                          { staticClass: "fs-weight" },
                                          [_vm._v("¥")]
                                        )
                                      : _vm._e(),
                                    _vm.price_status == 1
                                      ? _c(
                                          "span",
                                          { staticClass: "fs-weight" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.choosedGoods.price ||
                                                  _vm.goodsDetail.price
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                              _vm.goodsType == 1 || _vm.goodsType == 2
                                ? _c("div", [
                                    _vm.getVirtualStatus(
                                      _vm.goodsDetail.source_type
                                    )
                                      ? _c("p", { staticClass: "stock" }, [
                                          _vm._v(
                                            "\n                库存：" +
                                              _vm._s(
                                                _vm.goodsDetail.limit_item_info
                                                  .stock
                                              ) +
                                              _vm._s(
                                                _vm.goodsDetail.unit || "件"
                                              ) +
                                              "\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _c("div", [
                                    _vm.getVirtualStatus(
                                      _vm.goodsDetail.source_type
                                    )
                                      ? _c("p", { staticClass: "stock" }, [
                                          _vm._v(
                                            "\n                库存：" +
                                              _vm._s(_vm.choosedGoods.stock) +
                                              _vm._s(
                                                _vm.goodsDetail.unit || "件"
                                              ) +
                                              "\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                              _c("p", { staticClass: "num" }, [
                                _vm._v(
                                  "已选：" +
                                    _vm._s(_vm.num) +
                                    _vm._s(_vm.goodsDetail.unit || "件")
                                ),
                              ]),
                              _vm.goodsDetail.item_limit * 1 !== -1 &&
                              _vm.customAccount("VUE_APP_ZHONGHANG")
                                ? _c("p", { staticClass: "num" }, [
                                    _vm._v(
                                      "\n              限购：" +
                                        _vm._s(_vm.goodsDetail.item_limit) +
                                        _vm._s(_vm.goodsDetail.unit || "件") +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                      ]),
                      !!_vm.skuListCopy.length
                        ? _c(
                            "div",
                            { staticClass: "goods-sku" },
                            _vm._l(_vm.skuListCopy, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "sku-item" },
                                [
                                  _c("div", { staticClass: "sku-property" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "sku-value-box" },
                                    _vm._l(
                                      item.property_value_list,
                                      function (property, secIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: "0" + secIndex,
                                            class: [
                                              "sku-value",
                                              { active: property.active },
                                              { noUse: !property.canUse },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                property.canUse
                                                  ? _vm.chooseSkuItem(
                                                      item.id,
                                                      property.id
                                                    )
                                                  : ""
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(property.value) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm.goodsDetail.display_type === 3
                        ? _c(
                            "div",
                            { staticClass: "int-deducion" },
                            [
                              _c(
                                "yd-checkbox-group",
                                {
                                  model: {
                                    value: _vm.deducyion,
                                    callback: function ($$v) {
                                      _vm.deducyion = $$v
                                    },
                                    expression: "deducyion",
                                  },
                                },
                                [
                                  _c(
                                    "yd-checkbox",
                                    {
                                      attrs: {
                                        val: "1",
                                        shape: "circle",
                                        change: _vm.useIntegral(),
                                      },
                                    },
                                    [_vm._v("是否积分抵扣")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.integralShow()
                        ? _c("div", { staticClass: "integral-input" }, [
                            _c("div", { staticClass: "the-input" }, [
                              _c("div", { staticClass: "left" }, [
                                _c("span", [_vm._v("使用积分数：")]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("yd-input", {
                                    attrs: {
                                      type: "number",
                                      required: "",
                                      "show-success-icon": false,
                                      "show-error-icon": false,
                                      placeholder: "请输入使用积分数",
                                    },
                                    model: {
                                      value: _vm.integralNum,
                                      callback: function ($$v) {
                                        _vm.integralNum = $$v
                                      },
                                      expression: "integralNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "btn" },
                                [
                                  _c(
                                    "yd-button",
                                    {
                                      attrs: { size: "mini" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.setIntegral.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("确定")]
                                  ),
                                ],
                                1
                              ),
                              _vm.kunShan ||
                              _vm.tripartite_type === "VUE_APP_ZJG"
                                ? _c("div", { staticClass: "kunshan-tip" }, [
                                    _vm._v(
                                      "\n              您的积分余额为" +
                                        _vm._s(_vm.balance) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm.choosedGoods.price
                              ? _c("p", { staticClass: "tip" }, [
                                  _vm._v(
                                    "\n            积分使用区间：\n            " +
                                      _vm._s(
                                        _vm.integralPart(
                                          2,
                                          _vm.choosedGoods.price,
                                          _vm.goodsDetail
                                            .min_integral_percentage
                                        )
                                      ) +
                                      "\n            ~" +
                                      _vm._s(
                                        _vm.integralPart(
                                          2,
                                          _vm.choosedGoods.price,
                                          _vm.goodsDetail
                                            .max_integral_percentage
                                        )
                                      ) +
                                      "\n            ，\n            " +
                                      _vm._s(
                                        _vm.kunShan
                                          ? "请确保输入积分是500的正整数倍!"
                                          : _vm.tripartite_type ===
                                            "VUE_APP_ZJG"
                                          ? "请确保输入积分是5的正整数倍!"
                                          : "请确保积分余额充足！"
                                      ) +
                                      "\n          "
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.buyMore()
                        ? _c("div", { staticClass: "goods-num" }, [
                            _c("div", { staticClass: "num-title" }, [
                              _vm._v(
                                "\n            购买数量（" +
                                  _vm._s(_vm.goodsDetail.unit || "件") +
                                  "）\n          "
                              ),
                            ]),
                            _vm.goodsType == 1 || _vm.goodsType == 2
                              ? _c(
                                  "div",
                                  { staticClass: "num-spinner" },
                                  [
                                    _c("yd-spinner", {
                                      class:
                                        _vm.num == _vm.limitNum
                                          ? "skuAddBtn"
                                          : "",
                                      attrs: {
                                        callback: _vm.selectNum,
                                        max:
                                          (_vm.limitNum <
                                          _vm.goodsDetail.limit_item_info.stock
                                            ? _vm.limitNum
                                            : _vm.goodsDetail.limit_item_info
                                                .stock) || 1,
                                      },
                                      model: {
                                        value: _vm.num,
                                        callback: function ($$v) {
                                          _vm.num = $$v
                                        },
                                        expression: "num",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "num-spinner" },
                                  [
                                    _c("yd-spinner", {
                                      attrs: {
                                        max: _vm.choosedGoods.stock || 1,
                                      },
                                      model: {
                                        value: _vm.num,
                                        callback: function ($$v) {
                                          _vm.num = $$v
                                        },
                                        expression: "num",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm.goodsInfo.stock_name == "无货"
                    ? _c("div", { staticClass: "sku-btn no-stock" }, [
                        _vm._v("\n        该地区暂时无货\n      "),
                      ])
                    : _vm.buyType == 0
                    ? _c(
                        "div",
                        {
                          staticClass: "sku-btn",
                          class: _vm.btnStatus,
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.addCar()
                            },
                          },
                        },
                        [_vm._v("\n        加入购物车\n      ")]
                      )
                    : _vm.buyType == 1
                    ? _c(
                        "div",
                        {
                          staticClass: "sku-btn",
                          class: _vm.btnStatus,
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.buyNow(1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        立即" +
                              _vm._s(
                                _vm.tripartite("VUE_APP_TABEN") ||
                                  _vm.tripartite("VUE_APP_GUANGDALOGIN") ||
                                  _vm.customAccount("VUE_APP_CHAOWEI")
                                  ? "兑换"
                                  : "购买"
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm.buyType == 2
                    ? _c(
                        "div",
                        {
                          staticClass: "sku-btn",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.buyNow(1)
                            },
                          },
                        },
                        [_vm._v("\n        单独购买\n      ")]
                      )
                    : _vm.buyType == 3
                    ? _c(
                        "div",
                        {
                          staticClass: "sku-btn",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.buyNow(2)
                            },
                          },
                        },
                        [_vm._v("\n        我要开团\n      ")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }