//import { Count } from "./common"
import { getPointNum, getStore, toCash } from './common'
import { tripartite } from './custom.js'
import store from "../store.js";

// 返回商品类型
export const getGoodsType = goods => {
  let goodsType; // 商品类型判断  0： 普通商品，  1： 限时购商品在售状态   2： 限时购商品预售状态   3： 团购商品
	let item_active_type = goods.item_active_type;
  if (item_active_type == 1) {
    goodsType = 0;
  } else if (item_active_type == 2) {
    let nowDate = Date.parse(new Date()) / 1000;
    if (nowDate < goods.limit_buy_info.start_time) {
      // 预售状态
      goodsType = 2;
    } else if (
      nowDate >= goods.limit_buy_info.start_time &&
      nowDate < goods.limit_buy_info.end_time
    ) {
      // 在售状态
      goodsType = 1;
    } else {
      goodsType = 0;
      console.log("已经结束");
    }
  } else if (item_active_type == 3) {
    goodsType = 3;
	}
  return goodsType;
};

// 根据商品 和商品类型返回相关价格信息
export const getGoodsPrice = (goods, goodsType) => {
	let goodsPrice = {};
	goodsPrice.display_type = goods.display_type
	goodsPrice.max_integral = goods.max_integral
  if (goodsType == 0 || goodsType == 2) {
    if (goodsType == 2) {
      goodsPrice.future_price = goods.limit_item_info.limit_buy_price || '';
    }
    goodsPrice.real_price = goods.price;
    goodsPrice.del_price = goods.mall_price;
  } else if (goodsType == 1) {
    goodsPrice.real_price = goods.limit_item_info.limit_buy_price;
    goodsPrice.del_price = goods.mall_price;
  } else if (goodsType == 3) {
    goodsPrice.real_price = goods.group_buy_item_info.group_buy_price;
    goodsPrice.del_price = goods.price;
  }
  return goodsPrice;
};

// 获取商品banner
export const getBanners = list => {
  let banners = [];
  for (let i = 0; i < list.length; i++) {
    banners.push({
      img_url: list[i].base_pic
    });
  }
  return banners;
};

// 服务内容
export const SERVE_ITEMS = [
  { img: require("@/assets/ic_tui@2x.png"), content: "极速售后" },
  { img: require("@/assets/ic_send_quick@2x.png"), content: "极速配送" },
  { img: require("@/assets/ic_kongzhi@2x.png"), content: "品控质检" }
];

// 拼团玩法规则
export const GROUP_RULES = ["选择商品开团/参团", "邀请好友参团", "人满成团"];

/* export const integralType = (price, ratio, maxInt, num) => {
	let theCount = new Count();
	let showType = null;
	let integralRatio =  Number.parseFloat(theCount.divide(maxInt, 100)),
		moneyRatio = theCount.subtr(1, integralRatio),
		theMoney = Math.ceil(theCount.mul(theCount.mul(price, moneyRatio), 100)),
		moneyPath = theCount.divide(theMoney, 100),
		
		//最多可使用积分
		moreInt = Math.ceil(theCount.mul(theCount.subtr(price, moneyPath), ratio)),
		theInt = theCount.mul(moreInt, num);
	if (integralRatio === 1) {
		showType = `${theInt}积分`;
	} else {
		let	payMoney = theCount.mul(moneyPath, num);
		showType = `${theInt}积分+${payMoney}元`;
	}
	return showType;
}; */
// 积分商城显示积分+现金;参数依次为: 价格、积分比、最多可使用积分比例、数量
export const integralType = (price, ratio, maxInt, num) => {
	let showType = null;
	let integralRatio =  Number.parseFloat(maxInt / 100),
		moneyRatio = 1 - integralRatio,						//需要现金的比例
		thePrice = price * moneyRatio,
		theMoney = getPointNum(thePrice) === 0 ? thePrice * 100 : Math.ceil(thePrice * 100),
		moneyPath = theMoney / 100,
		
		//最多可使用积分
		moreInt = Math.ceil((price - moneyPath) * ratio),
		theInt = moreInt * num;
	if (integralRatio === 1) {
		showType = `${theInt}积分`;
	} else {
		let	payMoney = moneyPath * num;
		showType = `${theInt}积分+${payMoney.toFixed(2)}元`;
	}
	return showType;
};

//已销数量计算
export const getSales = (id, p) => {
	let str = (id - p).toFixed(0).toString();
	let r = Number(str.substr(str.length - 3));
	if (r >= 400) {
		return r - 300;
	} else{
		return r;
	}
	//return r;
};

/*
积分3.0计算规则
*/

/* 
价格展示
type: 商品类型；price: 商品价格；ratio: 商品可使用积分最大占比；
full: 结算页商品列表满减部分
 */
export const priceShow = (type, price, ratio, full) => {
	// console.log(getStore('config'));
	switch (type){
		case 0:
			return `￥${ price }`;
			break;
		case 1:
			if (full) {
				return `${ integralPart(type, price, 100) - full }积分`;
			} else {
				return `${ integralPart(type, price, 100) }积分`;
			}
			break;
		case 2:
			return `${(price - cashPart(integralPart(type, price, ratio))).toFixed(2)}元+${integralPart(type, price, ratio)}积分`;
			break;
		case 3:
			return `${ price }元`;
			break;
		default:
			break;
	}
};

/* 
积分部分（现金-->积分）
price: 商品价格；ratio:  商品可使用积分最大占比；
freight: 转换价格是否为运费；有值表示转换的是运费，没值则是商品价格
 */
export const integralPart = (type, price, ratio, freight) => {
	const config = JSON.parse(getStore('config')),
				yuan_num = config.yuan_num,
				credit_num = config.credit_num,
				percent = ratio / 100;
	const res = (Number(price) *100 / yuan_num) /100 * credit_num * percent;

	// 判断计算后是否为整数
	if (Number.isInteger(res)) {
		// 昆山农商行商城500处理
		if (tripartite('VUE_APP_KUNSHAN')) {
			if (freight) {
				return `${res}`;
			} else {
				// type为1，纯积分展示的商品不做500处理
				if (type === 1) {
					return `${res}`;
				} else {
					if (res < 500) {
						return 500;
					} else {
						const val = res / 500;
						if (Number.isInteger(val)) {
							return res;
						} else {
							return parseInt(val) * 500;
						}
					}
				}
			}
		} else {
			return `${res}`;
		}
	} else {
		const val = res < 1 && res > 0 ? 1 : parseInt(res);

		// 昆山农商行商城500处理
		if (tripartite('VUE_APP_KUNSHAN')) {
			// type为1，纯积分展示的商品不做500处理
			if (type === 1) {
				return val;
			} else {
				if (val < 500) {
					return 500;
				} else {
					const lastVal = val / 500;
					if (Number.isInteger(lastVal)) {
						return val;
					} else {
						return parseInt(lastVal) * 500;
					}
				}
			}
		} else {
			return val;
		}
	}
};

/* 
现金部分（积分-->现金）
integral: 积分值
 */
export const cashPart = (integral) => {
	const config = JSON.parse(getStore('config')),
		yuan_num = config.yuan_num,
		credit_num = config.credit_num;
	const res = integral / credit_num * yuan_num;

	// 判断计算后是否为整数
	if (Number.isInteger(res)) {
		return res;
	} else {
		return toCash(res);
	}
}

// 积分商城计算合计
export const dealTotal = (list) => {
	let theInt = 0,
		money = 0;
	list.forEach( item => {
		const number = item.number,
			price = Number.parseFloat(item.price),
			type = item.display_type;
		switch (type){
			case 0:
			case 3:
				if (store.state.tripartite_type === 'VUE_APP_ZJG') {
					money += (price - cashPart(item.integral_usage)).toFixed(2) * number;
					theInt += item.integral_usage * number;
				} else {
					money += price * number;
				}
				break;
			case 1:
				theInt += integralPart(type, price, 100) * number;
				break;
			case 2:
				money += (price - cashPart(item.integral_usage)).toFixed(2) * number;
				theInt += item.integral_usage * number;
				break;
			default:
				break;
		}
	});
	if (money.toFixed(2) > 0) {
		if (theInt > 0) {
			return money.toFixed(2) + '元+' +  theInt + '积分';
		} else {
			return money.toFixed(2) + '元';
		}
	} else{
		return theInt + '积分';
	}
}

/* const kunshanShop = res => {
	if (res < 500) {
		return 500;
	} else {
		const val = res / 500;
		if (Number.isInteger(val)) {
			return res;
		} else {
			return parseInt(val) * 500;
		}
	}
} */