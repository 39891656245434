var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banner" },
    [
      _c(
        "yd-slider",
        { attrs: { autoplay: "3000" } },
        _vm._l(_vm.banners, function (item, index) {
          return _c("yd-slider-item", { key: index }, [
            _c(
              "div",
              { staticClass: "img-box" },
              [
                _c("yqt-image", {
                  staticClass: "img",
                  attrs: {
                    src: _vm._f("ossProcess")(
                      _vm._f("setHttps")(item.img_url),
                      "style/w_960_h_auto"
                    ),
                    fit: "cover",
                    alt: "banner",
                  },
                }),
              ],
              1
            ),
          ])
        }),
        1
      ),
      _vm.showStues && _vm.zgyh_show
        ? _c(
            "div",
            {
              staticClass: "home-icon",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.goBack()
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/ic_back_28.png"), alt: "" },
              }),
            ]
          )
        : _vm._e(),
      _vm.showStues
        ? _c(
            "div",
            {
              staticClass: "collect-icon",
              on: {
                click: function ($event) {
                  return _vm.backHome()
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/icon_shangpin_home@2x.png"),
                  alt: "",
                },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }