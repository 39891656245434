<template>
  <div class="evaluate-list">
    <div :class="['item', { boxShadow: boxShadow }]" v-for="(item, index) in list" :key="index">
      <div class="item-top">
        <div class="user">
          <img :src="item.heagurl" alt="headimg">
          <span>{{item.nickname}}</span>
        </div>
        <div class="star"></div>
      </div>
      <div class="item-content">
        {{item.content}}
      </div>
      <div class="img-box">
        <img :src="img.img" alt="" v-for="(img, oindex) in item.photo_list" :key="oindex">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "evaluateList",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showStar: {
      // 是否展示评分，默认不展示
      type: Boolean,
      default: false
    },
    boxShadow: {
      // item是否有  box-shadow  默认没有
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  }
};
</script>
<style scoped lang="less">
.evaluate-list {
  .item {
    width: 6.9rem;
    padding: 0.24rem 0.2rem;
    box-sizing: border-box;

    .item-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 0.48rem;
      margin-bottom: 0.2rem;

      .user {
        display: flex;
        align-items: center;

        img {
          width: 0.48rem;
          height: 0.48rem;
          border-radius: 0.48rem;
          margin-right: 5px;
        }

        span {
          font-size: 0.24rem;
          color: #999999;
        }
      }
    }

    .item-content {
      font-size: 0.24rem;
      color: #333333;
      line-height: 0.34rem;
      margin-bottom: 0.2rem;
    }

    .img-box {
      display: flex;
      align-items: center;

      img {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.2rem;
      }
    }
  }
}
</style>