var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "evaluate-list" },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "div",
        { key: index, class: ["item", { boxShadow: _vm.boxShadow }] },
        [
          _c("div", { staticClass: "item-top" }, [
            _c("div", { staticClass: "user" }, [
              _c("img", { attrs: { src: item.heagurl, alt: "headimg" } }),
              _c("span", [_vm._v(_vm._s(item.nickname))]),
            ]),
            _c("div", { staticClass: "star" }),
          ]),
          _c("div", { staticClass: "item-content" }, [
            _vm._v("\n      " + _vm._s(item.content) + "\n    "),
          ]),
          _c(
            "div",
            { staticClass: "img-box" },
            _vm._l(item.photo_list, function (img, oindex) {
              return _c("img", {
                key: oindex,
                attrs: { src: img.img, alt: "" },
              })
            }),
            0
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }