var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "group-list" },
    [
      _c(
        "div",
        {
          staticClass: "list-top",
          on: {
            click: function ($event) {
              _vm.moreGroup ? _vm.lookMore() : ""
            },
          },
        },
        [
          _c("p", { staticClass: "tip" }, [
            _vm._v("以下小伙伴正在发起团购，你可以直接拼团"),
          ]),
          _c("p", { staticClass: "look-more" }, [
            _vm.moreGroup ? _c("span", [_vm._v("更多拼团")]) : _vm._e(),
          ]),
        ]
      ),
      _vm.groupList.length > 0
        ? _c(
            "div",
            { staticClass: "list-content" },
            _vm._l(_vm.groupList, function (item, index) {
              return _c("div", { key: index, staticClass: "list-item" }, [
                _c("div", { staticClass: "item-info" }, [
                  _c("div", { staticClass: "user-img" }, [
                    item.heagurl
                      ? _c("img", { attrs: { src: item.heagurl, alt: "" } })
                      : _c("img", {
                          attrs: {
                            src: require("../../assets/ic_head_default@3x.png"),
                            alt: "",
                          },
                        }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "user-info" },
                    [
                      _c("p", { staticClass: "user-name" }, [
                        _vm._v(_vm._s(item.nickname)),
                      ]),
                      _c(
                        "yd-countdown",
                        {
                          attrs: { time: item.end_time, timetype: "timestamp" },
                        },
                        [
                          _c("p", { staticClass: "count-down" }, [
                            _vm._v(
                              "还差 " +
                                _vm._s(item.surplus_num) +
                                " 人，剩余{%h}:{%m}:{%s}"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "item-btn",
                    on: {
                      click: function ($event) {
                        return _vm.toDetail(item)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/ic_pintuan@2x.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
              ])
            }),
            0
          )
        : _c("empty", { attrs: { tip: "拼团列表空空如也～" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }